import { render, staticRenderFns } from "./ImageFormComponent.vue?vue&type=template&id=493f89cf&scoped=true&"
import script from "./ImageFormComponent.vue?vue&type=script&lang=js&"
export * from "./ImageFormComponent.vue?vue&type=script&lang=js&"
import style0 from "./ImageFormComponent.vue?vue&type=style&index=0&id=493f89cf&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "493f89cf",
  null
  
)

export default component.exports